import React from 'react';
import PortfolioBlock from "./PortfolioBlock";
import { Box, Grid } from "@mui/material";
import { info } from "../../info/Info";

export default function Portfolio() {
    return (
        <Box>
            <Grid container display={'flex'} justifyContent={'center'}>
                {info.portfolio.map((project, index) => {
                    console.log(project.title);
                    return (
                        <Grid 
                            item xs={12} 
                            md={project.title === "Ginder music" ? 2 : 6}  
                            key={index} 
                        >
                            <PortfolioBlock 
                                image={project.image} 
                                live={project.live} 
                                source={project.source} 
                                title={project.title} 
                                opis={project.opis} 
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};