import self from "../img/ja.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"
import mock6 from "../img/mock6.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    imeIPrezime:"Filip Ginder",
    firstName: "Filip",
    lastName: "Ginder",
    initials: "js", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Full Stack Developer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'based in the Serbia'
        },
        {
            emoji: "💼",
            text: "Full Stack Engineer at Mega"
        },
        {
            emoji: "📧",
            text: "ginderfilip@gmail.com"
        }
    ],
    socials: [
        {
            link: "https://facebook.com",
            icon: 'fa fa-facebook',
            label: 'facebook'
        },
        {
            link: "https://instagram.com",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://twitter.com",
            icon: "fa fa-twitter",
            label: 'twitter'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hello! I'm Filip. I'm a systems engineer for Mega computer engineering with 4 years expiriance. I studied PHP at IT academy, I can say for myself that I am Communicative, quick learner, team worker, ambitious, i want to learn new things",
    skills:
        {
            proficientWith: ['html5', 'css3', 'bootstrap', 'material UI', 'javascript', 'jquery', 'react', 'redux', 'json', 'xml', 'PHP', 'node', 'coldfusion', 'web socket', 'sql', 'mysql', 'mssql', 'jquery', 'git', 'svn'],
            exposedTo: ['nodejs', 'python', 'adobe illustrator']
        }
    ,
    hobbies: [
        {
            label: 'droning',
            emoji: '🚁'
        },
        {
            label: 'theater',
            emoji: '🎭'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },
        {
            label: 'reading',
            emoji: '📖'
        }
        
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Online reservation",
            live: "https://online-rezervacije.rs/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1,
            opis:"jquery-php-mysql"
        },
        {
            title: "Memory game",
            live: "https://ginder.in.rs/",
            source: "https://github.com/paytonjewell",
            image: mock2,
            opis:"react-node-mysql"
        },
        {
            title: "QR code generator",
            live: "https://qr.ginder.in.rs/",
            source: "https://github.com/paytonjewell",
            image: mock3,
            opis:"react-node"
        },
        {
            title: "Online service books",
            live: "https://autoservisrve.com/",
            source: "https://github.com/paytonjewell",
            image: mock4,
            opis:"jquery-php-mysql"
        },
        {
            title: "Inplast",
            live: "https://inplast.rs/",
            source: "https://github.com/paytonjewell",
            image: mock5,
            opis:"Wordpress"
        },
        {
            title: "Ginder music",
            live: "https://drive.google.com/drive/folders/1Tpop7cVltMlE7-7ID44rHLKEwehSJypj?usp=drive_link",
            source: "https://github.com/paytonjewell",
            image: mock6,
            opis:"react-native android app"
        }
    ]
}